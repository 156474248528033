import { createSlice } from "@reduxjs/toolkit";

export const DrupalSlice = createSlice({
    name : "drupal",
    initialState : {
        showModal : false,
        isLoading : false,
        isSuccess : false,
        isError : false,
        errorIcon : "",
        errorMsg : "",
        fullname : "",
        email : "",
        currentBroadbandProvider : "",
        dwellingType : "",
        otherDwellingType : "",
        inContract : false,
        postalCode : "",
        unitNumber : "",
        question : "" 
    },
    reducers : {
        setLoading : (state, actions) => {
            state.isLoading = actions.payload
        },
        setSuccess : (state, actions) => {
            state.isSuccess = actions.payload
        },
        setError : (state, actions) => {
            state.isError = actions.payload
        },
        setErrorIcon : (state, actions) => {
            state.errorIcon = actions.payload
        },
        setErrorMsg : (state, actions) => {
            state.errorMsg = actions.payload
        },
        setShowModal :(state, actions) => {
            state.showModal = actions.payload
        },
        setFullname : (state, actions) => {
            state.fullname = actions.payload
        },
        setEmail : (state, actions) => {
            state.email = actions.payload
        },
        setCurrentBroadbandProvider : (state, actions) => {
            state.currentBroadbandProvider = actions.payload
        },
        setDwellingType : (state, actions) => {
            state.dwellingType = actions.payload
        },
        setOtherDwellingType : (state, actions) => {
            state.otherDwellingType = actions.payload
        },
        setInContract : (state, actions) => {
            state.inContract = actions.payload
        },
        setPostalCode : (state, actions) => {
            state.postalCode = actions.payload
        },
        setUnitNumber : (state, actions) => {
            state.unitNumber = actions.payload
        },
        setQuestion : (state, actions) => {
            state.question = actions.payload
        },
    }
})

export const {
    setLoading,
    setSuccess,
    setError,
    setErrorMsg,
    setErrorIcon,
    setShowModal,
    setFullname,
    setEmail,
    setCurrentBroadbandProvider,
    setDwellingType,
    setOtherDwellingType,
    setInContract,
    setPostalCode,
    setUnitNumber,
    setQuestion
} = DrupalSlice.actions

export default DrupalSlice.reducer